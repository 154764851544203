import React, { useState, useEffect, useCallback } from 'react';
import Select, { SingleValue, InputActionMeta } from 'react-select';
import ApiClient from "../../utilities/http";
import {Endpoints} from "../../utilities/endpoints";
import debounce from 'lodash.debounce';
import {Form} from "react-bootstrap";
import {Option} from "../../models/types";
import { useTranslation } from 'react-i18next';

// Define the shape of the API response
interface ApiResponse {
    id: string | number;
    name: string;
}

interface Props {
    onSelect: Function;
    value?: SingleValue<Option>; // Accept value from the parent
    label: string;
    name: string;
    id?: string;
    wrap?: boolean;
}

const SearchableSelect: React.FC<Props> = ({onSelect, ...props}) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);
    const [options, setOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<SingleValue<Option>>(null);

    // Fetch data from API
    const fetchOptions = async (inputValue: string): Promise<void> => {
        setLoading(true);
        try {
            const response = await ApiClient.get<ApiResponse[]>(Endpoints.SEARCH + '/location', {
                params: {
                    search_key: inputValue, // Query parameter for filtering
                    limit: 10,         // Limit the number of results
                },
            });
            const data = response.data;

            // Map API response to react-select format
            const formattedOptions: Option[] = data.map((item) => ({
                value: item.id,
                label: item.name,
            }));

            setOptions(formattedOptions);
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };

    // Debounced function to delay API calls
    const debouncedFetchOptions = useCallback(
        debounce((inputValue: string) => {
            fetchOptions(inputValue);
        }, 300), // Adjust debounce delay as needed
        []
    );

    // Handle input change
    const handleInputChange = (inputValue: string, _: InputActionMeta): void => {
        if (inputValue) {
            debouncedFetchOptions(inputValue);
        } else {
            setOptions([]);
        }
    };

    // Handle selection change
    const handleChange = (selected: SingleValue<Option>): void => {
        console.log('Selected option:', selected);
        setSelectedValue(selected);
        onSelect(selected);
    };

    useEffect(function(){
        if(props.value) setSelectedValue(props.value);
    }, [props.value]);

    const content = (
        <Select
            styles={{
                control: (base) => ({ ...base, boxShadow: 'none', width: '100%' }),
            }}
            options={options}
            name={props.name}
            isLoading={loading}
            isClearable
            placeholder={t('Search and select...')}
            value={selectedValue}
            onChange={handleChange}
            onInputChange={handleInputChange}
            noOptionsMessage={() => t('No options found')}
        />
    );
    const wrap = props.wrap !== false;
    return wrap ? (
        <Form.Group controlId={props.id || props.name}>
            <Form.Label>{t(props.label)}</Form.Label>
            {content}
        </Form.Group>
    ) : (
        content
    );
};

export default SearchableSelect;
