import React from 'react';
import { Form, Container, Row, Col } from 'react-bootstrap';
import '../../assets/styles/App.css';
import { Navigate } from 'react-router-dom';
import { Formik } from 'formik';
import LoginValidator from '../../validators/LoginValidator';
import { loginIn } from '../../actions/authActions';
import { connect } from 'react-redux';
import UIButton from '../utilities/UIButton';
import cookie from '../../utilities/cookie';
import { useTranslation } from 'react-i18next';

interface Props {
    dispatch: Function;
    isAuthenticated: boolean;
}

const Login: React.FC<Props> = ({ dispatch, isAuthenticated }) => {
    const tenant = cookie.get('tenant');
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt)

    return isAuthenticated && tenant ? (
        <Navigate to='/dashboard' />
    ) : (
        <Container className='mt-5 Login'>
            <Row className='justify-content-center'>
                <Col md={6}>
                    <h2 className='text-center'>{t('Login')}</h2>
                    <Formik
                        initialValues={{
                            platform: '',
                            username: '',
                            password: '',
                        }}
                        validationSchema={LoginValidator}
                        onSubmit={(values) => {
                            dispatch(loginIn(values.username, values.password, values.platform));
                        }}
                    >
                        {({ errors, touched, values, handleSubmit, handleChange }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className='mb-3' controlId='formBasicPlatform'>
                                    <Form.Label>{t('Platform')}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='platform'
                                        placeholder={t('Enter platform')}
                                        value={values.platform}
                                        onChange={handleChange}
                                        isValid={touched.platform && !errors.platform}
                                    />
                                    <Form.Control.Feedback type='invalid' tooltip>
                                        {errors.platform}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='formBasicUsername'>
                                    <Form.Label>{t('Username')}</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='username'
                                        placeholder={t('Enter Username')}
                                        value={values.username}
                                        onChange={handleChange}
                                        isValid={touched.username && !errors.username}
                                    />
                                    <Form.Control.Feedback type='invalid' tooltip>
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='mb-3' controlId='formBasicPassword'>
                                    <Form.Label>{t('Password')}</Form.Label>
                                    <Form.Control
                                        type='password'
                                        placeholder={t('Password')}
                                        name='password'
                                        value={values.password}
                                        onChange={handleChange}
                                        isValid={touched.password && !errors.password}
                                    />
                                    <Form.Control.Feedback type='invalid' tooltip>
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <UIButton id='login-button' type='submit' label={t('Login')} variant='primary' />
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Login);
