import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Badge } from "react-bootstrap";
import { IActivity } from "../models/IAsset";
import { connect } from "react-redux";
import { getMyActivities, setCurrentActivity, unSetCurrentActivity } from "../actions/activityActions";
import ActivityDetails from "../components/activity-details";
import {
    CalendarClock,
    Fingerprint,
    CircleDot,
    MapPin,
    User,
    Users,
    ShieldQuestion,
    AlertOctagon,
    Activity
} from "lucide-react";
import { Utility } from "../utilities/utility";
import { useTranslation } from "react-i18next";

interface IActivityProp {
    activities: IActivity[];
    currentAct: IActivity;
    dispatch: Function;
}

const Activites: React.FC<IActivityProp> = ({ activities, currentAct, dispatch }) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    const [isTeamView, setIsTeamView] = useState(true);
    const [showCompleted, setShowCompleted] = useState(false);

    const handleShowCompleted = () => {
        dispatch(getMyActivities(false, !showCompleted, !isTeamView));
        setShowCompleted(!showCompleted);
    };

    const handleTeamView = (isTeam: boolean) => {
        setShowCompleted(false);
        dispatch(getMyActivities(false, false, !isTeam));
        setIsTeamView(isTeam);
    };

    const handleStartPerforming = (activity: IActivity) => {
        console.log("Started Performing Activity:", activity);
    };

    const handleDetailClose = () => {
        setShowCompleted(false);
        dispatch(unSetCurrentActivity());
    };

    useEffect(() => {
        dispatch(getMyActivities(false, false, !isTeamView));
    }, [currentAct]);

    if (currentAct) {
        return <ActivityDetails onClose={handleDetailClose} onStartPerforming={handleStartPerforming} />;
    }

    return (
        <div className="container py-3">
            {/* Toggle Buttons */}
            <Row className="mb-3">
                <Col xs={6}>
                    <Button
                        variant={isTeamView ? "success" : "outline-secondary"}
                        className="w-100"
                        onClick={() => handleTeamView(true)}
                        style={{ padding: "0.5rem" }}
                    >
                        {t("for_my_teams")}
                    </Button>
                </Col>
                <Col xs={6}>
                    <Button
                        variant={!isTeamView ? "success" : "outline-secondary"}
                        className="w-100"
                        onClick={() => handleTeamView(false)}
                        style={{ padding: "0.5rem" }}
                    >
                        {t("for_me")}
                    </Button>
                </Col>
            </Row>

            {/* Activities Header */}
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h5 className="mb-0">{t("activities")} ({activities.length})</h5>
                <Form.Check
                    type="checkbox"
                    label={t("show_completed")}
                    checked={showCompleted}
                    onChange={handleShowCompleted}
                    className="small"
                />
            </div>

            {/* Activities List */}
            <Row>
                {activities.map((activity) => (
                    <Col xs={12} className="mb-3" key={activity.activity_uid}>
                        <div
                            className="border rounded p-3 position-relative shadow-sm"
                            onClick={() => dispatch(setCurrentActivity(activity))}
                            style={{ cursor: "pointer", backgroundColor: "#f9f9f9" }}
                        >
                            {/* Left Border for Priority */}
                            <div
                                className="position-absolute top-0 bottom-0 start-0 rounded-start"
                                style={{
                                    width: "5px",
                                    backgroundColor: activity.priority === 1 ? "red" : "blue",
                                }}
                            ></div>

                            {/* Activity Details */}
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="d-flex align-items-center">
                                        <strong className="me-2">
                                            <Fingerprint size={16} className="me-1" />
                                            {activity.activity_uid}
                                        </strong>
                                    </div>

                                    <span className="text-muted small">
                                        <CalendarClock size={16} className="me-1" />
                                        {activity.due_date
                                            ? new Date(activity.due_date).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "short",
                                                day: "numeric",
                                            })
                                            : t("no_due_date")}
                                    </span>
                                </div>
                                <p className="mb-1 border rounded p-2 text-capitalize text-info-emphasis">
                                    {activity.description || t("no_description")}
                                </p>
                            </div>

                            {/* Priority and Assigned To */}
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <span className="text-muted small">
                                    <User size={16} className="me-1" />
                                    {activity.user?.name || t("no_assignee")}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-2">
                                <span className="text-muted small">
                                    <Users size={16} className="me-1" />
                                    {activity.user_group?.name || t("no_team")}
                                </span>
                                <Badge bg={activity.priority === 1 ? "danger" : "primary"}>
                                    {activity.priority === 1 ? <ShieldQuestion size={20} className="me-1" /> : <AlertOctagon size={20} className="me-1" />}
                                    {t("priority_p")} {activity.priority}
                                </Badge>
                            </div>
                            <div className="d-flex border rounded p-1 justify-content-between align-items-center mb-2 mt-2">
                                <span className="text-info small">
                                    <CircleDot size={16} className="me-1" />
                                    {Utility.shorten(activity.status?.name || t("unknown"), 10)}
                                </span>
                                <span className="bg-light text-dark d-flex align-items-center text-lg text-capitalize">
                                    <Activity size={16} className="me-1" />
                                    {activity.activity_type?.name}
                                </span>
                                <span className="text-muted small">
                                    <MapPin size={16} className="me-1" /> {Utility.shorten(activity.location?.name || t("no_location"), 16, "mid")}
                                </span>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    activities: state.activity.my_activities,
    currentAct: state.activity.activity,
});

export default connect(mapStateToProps)(Activites);
