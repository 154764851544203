import * as actionTypes from '../actionsTypes';
import { ICard } from '../models/ICard';
import { ILocationState } from "../models/ILocation";

const initialLocationState: ILocationState = {
    probed_location: undefined,
    location: undefined,
    currentCard: undefined,
    countedCards: undefined
};

export const inventoryLocation = (state: ILocationState = initialLocationState, action: any): ILocationState => {

    switch (action.type) {
        case actionTypes.PROBE_LOCATION2: {
            return {
                location: action.payload
            }
        }
        case actionTypes.CLEAR_LOCATION: {
            return {
                location: undefined
            }
        }
        case actionTypes.UPDATE_LOCATION: {
            let card = action.payload.card as ICard;
            card.countedInventory = action.payload.quantity;

            if (state.countedCards === undefined) {
                //we have no counted cards yet
                return {
                    location: state.location,
                    countedCards: [card]
                }
            } else {
                //update or add the card to countedCards
                let countedCards = state.countedCards;
                let existingCard = countedCards.find(e => e.id === card.id);

                if (existingCard === undefined) {
                    countedCards.unshift(card);
                } else {
                    existingCard.countedInventory = card.countedInventory;//this updates countedCards in-place
                }
                return {
                    location: state.location,
                    countedCards: countedCards
                }
            }
        }
        default: {
            return state;
        }
    }
};