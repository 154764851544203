import React, { MouseEventHandler } from "react";
import { Button, Modal } from "react-bootstrap";
import {useTranslation} from "react-i18next";

export function ResetConfirmationModal({ show, msg, headMsg = null, submitHandler, closeHandler }: { show: boolean, msg: string, submitHandler: any, closeHandler: any, headMsg: string | null }) {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    return (
        <Modal show={show} onHide={closeHandler}>
            <Modal.Header closeButton>
                <Modal.Title>{headMsg ? headMsg : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>{msg}</p>
                </>
                <Button style={{ width: '6rem' }} variant="outline-danger" onClick={submitHandler}
                >{t('ok')}</Button>
            </Modal.Body>
        </Modal>
    )
}
