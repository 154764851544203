import {staging} from './env/staging';
import {d_local} from './env/d.local';
import {e_local} from './env/e.local';
import {local} from './env/local';
import {production} from './env/production';
import {Utility} from "./utility";

const environment = process.env.REACT_APP_APP_ENV;
console.log(process.env)
let config = local;
if (environment === 'development') { config = local; }
if (environment === 'd_development') { config = d_local; }
if (environment === 'e_development') { config = e_local; }
if (environment === 'production') {
    if(process.env.REACT_APP_APP_STAGE && process.env.REACT_APP_APP_STAGE === 'live')
        config = production;
    else
        config = staging
}

config.api = Utility.parseTenantPath(config.api)

export default config;
