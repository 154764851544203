import { v4 as uuidv4 } from 'uuid';

import {ILoading, IMsg, IToastsState, IToastState} from "../models/types";
import  * as actionTypes from "../actionsTypes"
import {bindActionCreators} from "@reduxjs/toolkit";
import {actions as toastrActions} from 'react-redux-toastr';

export const isSubmitting = (authData: ILoading) => ({
    type    : actionTypes.SUBMIT_STARTS,
    payload : {...authData}
})

export const endSubmitting = (authData: ILoading) => ({
    type    : actionTypes.SUBMIT_ENDS,
    payload : {...authData}
})

const dissectError = (error: any, title: string, message: string = ""): IMsg => {
    if(error.response == undefined || (error.response.headers['content-type'].indexOf('text/html') > -1)){
        return {
            title: title || 'An Error Occurred',
            type:  'error',
            message: message || 'Oops! An error occurred please try again later'
        }
    } else {
        if(error.response.data.message){
            return {
                title: title,
                type:  'error',
                message: error.response.data.message
            }
        }
        let msg = '';
        for(const dt in error.response.data){
            if(dt == 'user') continue;
            msg += error.response.data[dt][0];
        }

        return {
            title: title,
            type:  'error',
            message: msg
        }
    }
}

export const toastError = (error: any, title: string, message: string = "") => {
    const processedError = dissectError(error, title, message);
    return (dispatch: any) => {
        const toastr = bindActionCreators(toastrActions as any, dispatch);
        const uuid = uuidv4();

        toastr.add({
            id: uuid,
            type: processedError.type,
            title: processedError.title,
            attention: true, // This will add a shadow like the confirm method.
            message: processedError.message,
            options: {}
        });

        // toastr.remove(uuid);
    }
}

export const toastSuccess = (title: string = 'Successfully Done', message: string = "Action successfully processed", data: any = []) => {
    return (dispatch: any) => {
        const toastr = bindActionCreators(toastrActions as any, dispatch);
        const uuid = uuidv4();
        toastr.add({
            id: uuid,
            type: 'success',
            title: title,
            attention: true, // This will add a shadow like the confirm method.
            message: message,
            options: {}
        });
        // dispatch(closeModal());
        // toastr.remove(uuid)
    }
}

export const setToast = (toasts: IToastState[]) => {
    return (dispatch: any) => dispatch({
        type: actionTypes.SET_TOAST,
        payload: [
            ...toasts
        ]
    })
}

export const dispatchDeleteConfirm = (confirmed: boolean) => ({
    type: actionTypes.CONFIRM_DELETE,
    payload: confirmed
})

export const addError = (error: []) => ({
    type    : actionTypes.ADD_ERROR,
    payload : {errors: error}
})

export const clearError = () => ({
    type    : actionTypes.CLEAR_ERROR,
    payload : {errors: []}
})

export const addInfo = (info: []) => ({
    type    : actionTypes.ADD_INFO,
    payload : {infos: info}
})

export const clearInfo = () => ({
    type    : actionTypes.CLEAR_INFO,
    payload : {infos: []}
})
