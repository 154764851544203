import { ICard } from '../models/ICard';
import { Modal, Table } from 'react-bootstrap';
import { Ellipsized } from './ellipsized';
import { useTranslation } from 'react-i18next';

export function CardsToScanModal({ show, cardList, closeHandler }: { show: boolean, cardList: Array<ICard>, closeHandler: any }) {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    return (
        <>
            <Modal show={show} onHide={closeHandler}>
                <Modal.Header closeButton>{t("still_to_be_scanned")}</Modal.Header>
                <Modal.Body>
                    <Table>
                        <tbody>
                        {
                            cardList.sort((a, b) => Number(b.scan_mandatory) - Number(a.scan_mandatory))
                                .map((e, idx) => (
                                    <tr key={idx}>
                                        <td>{e.product_code}</td>
                                        <td>
                                            <Ellipsized txt={e.gtin_name} num={30} />
                                        </td>
                                        <td>{e.scan_mandatory ? t("mandatory") : t("optional")}</td>
                                    </tr>
                                ))
                        }
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
}
