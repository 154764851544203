import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(HttpBackend) // Loads translations from public/locales folder
    .use(LanguageDetector) // Detects user language
    .use(initReactI18next) // Initializes with React
    .init({
        fallbackLng: "en", // Default language
        debug: true,
        interpolation: {
            escapeValue: false, // React already protects against XSS
        },
        backend: {
            loadPath: "/locales/{{lng}}.json" // Path to translation files
        }
    });

export default i18n;
