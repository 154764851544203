import React, { useState } from 'react';
import '../assets/styles/bottom-sheet.css';
import {useTranslation} from "react-i18next";

interface BottomSheetProps {
    children: React.ReactNode;
    isOpen: boolean;
    size?: string;
    onClose: (callback?: Function) => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ children, isOpen, onClose, size  }) => {
    const sizeClass = 'open' + (size ? '-' + size : '')
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    return (
        <>
            <div className={`bottom-sheet-overlay  ${isOpen ? 'open' : ''}`} onClick={() => onClose()}></div>
            <div className={`bottom-sheet ${isOpen ? sizeClass : ''}`}>
                <div className="bottom-sheet-content">
                    {children}
                    <button style={{display: 'none'}} onClick={() => onClose()} className="close-btn">{t('close')}</button>
                </div>
            </div>
        </>
    );
};

export default BottomSheet;
