import React, { useState } from "react";
import { IActivity } from "../models/IAsset";
import { Button, Row, Col, Form } from "react-bootstrap";
import { ScanQrCode, MonitorCog, ScanLine, CalendarClock } from "lucide-react";
import BottomSheet from "./bottom-sheet";
import { Form as IKForm, Formik, FormikHelpers } from "formik";
import ScannerField from "./utilities/ScannerField";
import UIButton from "./utilities/UIButton";
import * as Yup from "yup";
import { SingleValue } from "react-select";
import { Option } from "../models/types";
import { modifyActivity } from "../actions/activityActions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IActivityDetailsProps {
    activity: IActivity;
    onClose: () => void;
    dispatch: Function;
    onStartPerforming: (activity: IActivity) => void;
}

interface FormValues {
    old_location_id?: SingleValue<Option>;
    new_location_id?: SingleValue<Option>;
    asset_id?: SingleValue<Option>;
    remark?: string;
}

const ActivityDetails: React.FC<IActivityDetailsProps> = ({
                                                              activity,
                                                              onClose,
                                                              onStartPerforming,
                                                              dispatch,
                                                          }) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);
    const [isSheetOpen, setIsSheetOpen] = useState(false);
    const [currentItinerary, setCurrentItinerary] = useState("");
    const nav = useNavigate();

    const closeSheet = () => setIsSheetOpen(false);

    const showItinerary = activity.activity_type?.show_scan_asset ||
        activity.activity_type?.show_scan_start_location ||
        activity.activity_type?.show_scan_end_location ||
        activity.activity_type?.show_start_location ||
        activity.activity_type?.show_end_location;

    const showFinishButton = () => {
        if (!showItinerary) return true;
        if (activity.status.name === 'To Do') return false;

        return [
            !activity.scan_asset || (activity.asset_id && activity.asset_scanned),
            !activity.scan_start_location || (activity.old_location_id && activity.start_location_scanned),
            !activity.scan_finish_location || (activity.new_location_id && activity.finish_location_scanned),
        ].every(Boolean);
    };

    const enableItinerary = (itin: string) => {
        if (activity.status?.name !== 'In Execution') return false;
        return {
            scan_asset: !activity.asset_id || !activity.asset_scanned,
            scan_start_location: !activity.old_location_id || !activity.start_location_scanned,
            scan_end_location: !activity.new_location_id || !activity.finish_location_scanned
        }[itin] || false;
    };

    const handleAction = (action: string) => {
        setCurrentItinerary(action);
        setIsSheetOpen(true);
        onStartPerforming(activity);
    };

    const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
        const data: any = {
            id: activity.id,
            itinerary: currentItinerary,
            remark: values.remark,
            old_location_id: values.old_location_id?.value,
            new_location_id: values.new_location_id?.value,
            asset_id: values.asset_id?.value,
        };

        dispatch(modifyActivity(data));
        actions.setSubmitting(false);
        setIsSheetOpen(false);
    };

    return (
        <div className="container py-3">
            <div id="reader" style={{ zIndex: 101 }}></div>

            {/* Activity Details */}
            <div className="border rounded p-3 mb-3">
                <h5 className="mb-3">
                    {t("activity details")} - <strong>{activity.activity_uid}</strong>
                </h5>
                <p className="mb-2"><strong>{t("activity.type")}:</strong> {activity.activity_type?.name || "N/A"}</p>
                <p className="mb-2"><strong>{t("activity.asset type")}:</strong> {activity.asset_type?.name || "N/A"}</p>
                <p className="mb-2"><strong>{t("activity.priority")}:</strong> P{activity.priority || 0}</p>
                <p className="mb-2"><strong>{t("activity.assigned to")}:</strong> {activity.user?.name || "N/A"}</p>
                <p className="mb-2"><strong>{t("activity.due date")}:</strong> {activity.due_date ? new Date(activity.due_date).toLocaleDateString() : t("activity.no due date")}</p>
                <p className="mb-2 text-danger"><strong>{t("activity.status")}:</strong> {activity.status?.name || "N/A"}</p>
            </div>

            {/* Itinerary Section */}
            {showItinerary && (
                <div className="border rounded p-3 mb-3">
                    <h3>{t("activity.itinerary")}</h3>
                    {activity.scan_asset && (
                        <Row className="mb-1">
                            <Col xs={6}>{t("activity.scan_asset")}</Col>
                            <Col xs={3} className="pt-2" style={{ fontStyle: "italic" }}>
                                {activity.asset_scanned ? t("status.done") : t("status.pending")}
                            </Col>
                            <Col xs={3}>
                                <button className="btn btn-primary" disabled={!enableItinerary('scan_asset')} onClick={() => handleAction('scan_asset')}>
                                    <MonitorCog size={16} />
                                </button>
                            </Col>
                        </Row>
                    )}
                </div>
            )}

            {/* Action Buttons */}
            <Row>
                <Col xs={6}>
                    {showFinishButton() ? (
                        <Button variant="primary" className="w-100" disabled={activity.status?.name === "Finished"} onClick={() => handleAction('finish')}>
                            {t("actions.finish")}
                        </Button>
                    ) : (
                        <Button variant="primary" className="w-100" disabled={currentItinerary === "pickup" || activity.status?.name !== "To Do"} onClick={() => handleAction('pickup')}>
                            {t("actions.start")}
                        </Button>
                    )}
                </Col>
                <Col xs={6}>
                    <Button variant="outline-secondary" className="w-100" onClick={onClose}>
                        {t("actions.close")}
                    </Button>
                </Col>
            </Row>

            {/* Bottom Sheet */}
            <BottomSheet isOpen={isSheetOpen} onClose={closeSheet} size="sm">
                <h2 className="text-capitalize">{t(`activity.${currentItinerary}`)}</h2>
                <Formik initialValues={{ remark: "" }} onSubmit={handleSubmit}>
                    {({ values, handleChange, handleSubmit }) => (
                        <IKForm noValidate onSubmit={handleSubmit}>
                            <Form.Label>{t("remark")}</Form.Label>
                            <Form.Control as="textarea" name="remark" value={values.remark} onChange={handleChange} />
                            <UIButton variant="primary" type="submit" label={t("submit")} id="request-sub-btn" className="mt-3" />
                        </IKForm>
                    )}
                </Formik>
            </BottomSheet>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    activity: state.activity.activity
});

export default connect(mapStateToProps)(ActivityDetails);
