import {configureStore} from "@reduxjs/toolkit";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './reducers/rootReducer';

export const history = createBrowserHistory();

// Configure the persist options
const persistConfig = {
    key: 'root',
    whitelist: ['auth','foo','inventoryLocation'],
    storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(routerMiddleware(history)),
    reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;
