import { Button, Table } from "react-bootstrap";
import { ICard } from "../models/ICard";
import { MouseEventHandler } from "react";
import { Ellipsized } from "./ellipsized";
import { useTranslation } from "react-i18next";

interface CountedCardsTableProps {
    cards: ICard[];
    clickHandler: MouseEventHandler<HTMLTableRowElement>;
}

const CountedCardsTable: React.FC<CountedCardsTableProps> = ({ cards, clickHandler }) => {
    const { i18n } = useTranslation();
    if (!cards || cards.length === 0) {
        return null;
    }
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    return (
        <Table responsive>
            <thead>
            <tr style={{ fontWeight: 'bold' }}>
                <td>{t("product_code")}</td>
                <td>{t("description")}</td>
                <td>{t("count")}</td>
            </tr>
            </thead>
            <tbody>
            {cards.map((card) => (
                <tr key={card.id} id={card.id} onClick={clickHandler}>
                    <td>{card.product_code}</td>
                    <td><Ellipsized txt={card.gtin_name} num={20} /></td>
                    <td>{card.countedInventory}</td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
}

export default CountedCardsTable;
