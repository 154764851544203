import React, { useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { X, ScanQrCode, Flashlight, FlashlightOff } from "lucide-react";
import { useTranslation } from "react-i18next";

interface QRScannerProps {
    onResult: (decodedText: string) => void;
    scanOnload?: boolean;
    id?: string;
    compact?: boolean;
}

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
let html5QrCode: Html5Qrcode | null = null;

const QRScanner: React.FC<QRScannerProps> = ({ onResult, compact = false, scanOnload = false, id = 'reader' }) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    const [isScanning, setIsScanning] = useState<boolean>(false);
    const [flashlightOn, setFlashlightOn] = useState(false);

    const qrCodeSuccessCallback = (decodedText: string) => {
        onResult(decodedText);
        stopScanning();
    };

    const qrCodeErrorCallback = (err: any) => {
        console.log(err);
    };

    const isTorchSupported = () => {
        if (html5QrCode) {
            let settings = html5QrCode.getRunningTrackSettings();
            return "torch" in settings;
        }
        return false;
    };

    const toggleFlashlight = async (enable: boolean) => {
        if (html5QrCode && isTorchSupported()) {
            try {
                await html5QrCode.applyVideoConstraints({
                    torch: true,
                    // @ts-ignore
                    advanced: [{ "torch": enable }]
                });
            } catch (err) {
                console.error("Error applying video constraints:", err);
            }
        } else {
            console.warn("Torch is not supported on this device.");
        }
    };

    const handleFlashlightToggle = () => {
        const newState = !flashlightOn;
        setFlashlightOn(newState);
        toggleFlashlight(newState);
    };

    useEffect(() => {
        if (!html5QrCode) {
            html5QrCode = new Html5Qrcode(id);

            if (scanOnload) {
                startScanning();
            }
        }

        return () => {
            stopScanning();
        };
    }, [scanOnload]);

    const startScanning = () => {
        if (html5QrCode && !isScanning) {
            html5QrCode.start(
                { facingMode: 'environment' },
                qrConfig,
                qrCodeSuccessCallback,
                qrCodeErrorCallback
            )
                .then(() => {
                    setIsScanning(true);
                })
                .catch((err: any) => {
                    console.error(`Unable to start scanning: ${err}`);
                });
        }
    };

    const stopScanning = () => {
        if (html5QrCode && (html5QrCode.isScanning || isScanning)) {
            html5QrCode.stop()
                .then(() => {
                    html5QrCode?.clear();
                    setIsScanning(false);
                })
                .catch((err: any) => {
                    console.warn(`Cannot stop, scanner is not running: ${err}`);
                });
        }
    };

    return (
        <>
            {compact ? ('') : (<div id={id} style={{ width: '100%', zIndex: 101 }} />)}
            {isScanning ? (
                <button type="button" className={"btn btn-outline-danger " + (compact ? '' : 'mt-3')} style={compact ? { marginLeft: '10px' } : {}} onClick={stopScanning}>
                    {compact ? <X /> : t("stop_scanning")}
                </button>
            ) : (
                <button type="button" className={"btn btn-outline-primary " + (compact ? '' : 'mt-3')} style={compact ? { marginLeft: '10px' } : {}} onClick={startScanning}>
                    {compact ? <ScanQrCode /> : t("start_scanning")}
                </button>
            )}
            {isScanning && isTorchSupported() && (
                <button type="button" className={"btn btn-outline-primary " + (compact ? '' : 'mt-3')} style={compact ? { marginLeft: '10px' } : {}} onClick={handleFlashlightToggle}>
                    {compact ? (flashlightOn ? <FlashlightOff /> : <Flashlight />) : (flashlightOn ? t("turn_off_flashlight") : t("turn_on_flashlight"))}
                </button>
            )}
        </>
    );
};

export default QRScanner;
