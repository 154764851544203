import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import store from './store'

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import "./i18n";

import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {IRoute} from "./models/types";
import routes from "./routes";
import AuthService from "./services/AuthService";

const RouteLoader = () => {
    const authLoader = async () => {
        return AuthService.getUserId();
    }

    const setupRouting = () => {
        const routeList: IRoute[] = routes;

        return createBrowserRouter(createRoutesFromElements(
            routeList.map(rt => {
                const LayoutComponent = rt.as;
                const RenderedComponent = rt.component
                const AppIndex = rt.appIndex
                if(AppIndex){
                    return (
                        <Route path={rt.path} key={rt.path} element={<LayoutComponent />}>
                            <Route  path={rt.path} element={<AppIndex />} loader={rt.protected ? authLoader : undefined}>
                                <Route index element={<RenderedComponent />}></Route>
                            </Route>
                        </Route>)
                }
                return (
                    <Route path={rt.path} key={rt.path} element={<LayoutComponent />} action={ authLoader }>
                        <Route index element={<RenderedComponent />}></Route>
                    </Route>)
            })
        ))
    }

    return <RouterProvider router={setupRouting()}/>;
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <RouteLoader></RouteLoader>
      </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
