import React, { useEffect, useState } from 'react';
// import doc from '../assets/images/doc.png';
import defaultBackground from '../assets/images/bg-default.png';
import logo from '../assets/images/logo.png';
import '../assets/styles/App.css';
import { Navbar, Container } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import cookie from '../utilities/cookie';
import {Utility} from "../utilities/utility";

const Public = () => {
    const navigate = useNavigate();
    const [backgroundImage, setBackgroundImage] = useState(defaultBackground);

    useEffect(() => {
        const backgroundURL = cookie.get('background_url');

        if (backgroundURL) {
            Utility.checkIfImageExists(backgroundURL).then((exists) => {
                if (exists) {
                    setBackgroundImage(backgroundURL);
                }
            });
        }
    }, []);

    return (
        <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '100%', backgroundOrigin: 'content-box'}}>
            {/* Header */}
            <header>
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand className="fw-bold" onClick={() => navigate('/dashboard')}>
                            <img src={logo} style={{ opacity: 0 }} alt="Logo" />
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            </header>

            {/* Body Content */}
            <main className="container mt-4 vh-100">
                <h2 className="d-none">Shopfloor Manager</h2>
                <Outlet />
            </main>

            <ReduxToastr
                timeOut={7000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
            />

            {/* Footer */}
            <footer className="bg-dark text-white text-center py-3 mt-4">
                <p>&copy; 2024 Mobile App, All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default Public;
