import ApiClient, { parseRequestData } from "../utilities/http";
import {Endpoints} from "../utilities/endpoints";
import {endSubmitting, isSubmitting} from "./loadActions";
import {get} from "lodash";
import {IActivity, IActivityType, IAsset} from "../models/IAsset";
import * as actionTypes from "../actionsTypes";
import {toastError, toastSuccess} from "./formInputAction";

export const assetProbe = (code: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        ApiClient.get(Endpoints.ASSET + '?code=' + encodeURIComponent(code))
            .then(function (response){
                dispatch(endSubmitting({
                    submitButton: false,
                }));
                if(get(response, 'statusText') == 'OK'){
                    const assetItem: IAsset = get(response, 'data.asset_item');
                    if(assetItem){
                        dispatch({
                            type: actionTypes.PROBE_ASSET,
                            payload: {...assetItem}
                        })
                        return;
                    }
                }
                dispatch(toastError({}, 'Unknown Scanned Asset', 'The scan code does not resolve to any asset'))
            })
            .catch(function (error){
                dispatch(toastError(error, 'Error Fetching Asset', 'Asset could be fetched, please try again'))
            })
    }
}
