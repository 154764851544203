import React from "react";
import QRScanner from "../components/QRScanner";
import {
    // locationProbe,
    locationProbe2
} from "../actions/locationActions";
import { ICountedCard, ILocation } from "../models/ILocation";
import { ICard } from "../models/ICard";
import { connect } from "react-redux";
import * as actionTypes from "../actionsTypes";
import { Button } from "react-bootstrap";
import ApiClient from "../utilities/http";
import { Endpoints } from "../utilities/endpoints";
import { toastError, toastSuccess } from "../actions/formInputAction";
import InventoryCountModal from "../components/count-inventory-modal";
import CountedCardsTable from "../components/counted-cards-table";
import { submitInventoryCounts2 } from "../actions/inventoryActions";
import { CardsToScanModal } from "../components/count-inventory-todo";
import { ResetConfirmationModal } from "../components/reset-inventorycounting-modal";
import {Fingerprint, Info} from "lucide-react";
import { FC } from "react";
import { MapPin, ClipboardList, CheckCircle, XCircle } from "lucide-react";
import {useTranslation} from "react-i18next";

interface ScanStatusWidgetProps {
    location: string;
    totalCards: number;
    scanned: number;
}

const ScanStatusWidget: FC<ScanStatusWidgetProps> = ({ location, totalCards, scanned }) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string, defaultObj?: object) => i18n.t(txt, defaultObj);

    const remaining = totalCards - scanned;

    return (
        <div className="card shadow-sm border-0 p-3 mb-3 mt-2">
            <div className="row">
                <div className="col-5 p-2 my-auto">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <MapPin size={32} className="text-primary me-2" />
                        <h6 className="mb-0 text-center text-capitalize">{location}</h6>
                    </div>
                </div>
                <div className="col-7 p-2">
                    <div className="row mb-1">
                        <div className="col-9">
                            <h6 className="text-muted d-flex">
                                <ClipboardList size={18} className="me-1" />
                                {t("total_cards")}
                            </h6>
                        </div>
                        <div className="col-3">
                            <span className="fw-bold">{totalCards}</span>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-9">
                            <h6 className="text-muted d-flex">
                                <CheckCircle size={18} className="me-1 text-success" />
                                {t("scanned")}
                            </h6>
                        </div>
                        <div className="col-3">
                            <span className="text-success fw-bold">{scanned}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <h6 className="text-muted d-flex">
                                <XCircle size={18} className="me-1 text-danger" />
                                {t("remaining")}
                            </h6>
                        </div>
                        <div className="col-3">
                            <span className="text-danger fw-bold">{remaining}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface Props {
    dispatch: Function;
    location: ILocation;
    countedCards: [ICard];
}

enum WorkflowStage {
    SCAN_LOCATION = 1,
    LOCATION_ERROR,//2
    SCAN_CARD,//3
    CARD_ERROR,//4
    CARD_SCANNED,//5
    READY_TO_SEND,//6
    DONE,//7
}

const ScanInventory: React.FC<Props> = ({ dispatch, location, countedCards }) => {
    const [currentCard, setCurrentCard] = React.useState<ICard>();
    const [showModal, setShowModal] = React.useState(false);
    const [stage, setStage] = React.useState<WorkflowStage>(WorkflowStage.SCAN_LOCATION);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [sendButtonDisabled, setSendButtonDisabled] = React.useState<boolean>(false);
    const [showCardsToScan, setShowCardsToScan] = React.useState<boolean>(false);
    const [showResetConfirmation, setShowResetConfirmation] = React.useState<boolean>(false);

    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string, defaultObj?: object) => i18n.t(txt, defaultObj);

    React.useEffect(() => {
        manageWorkflow();
    }, [location, stage,currentCard]);

    function manageWorkflow() {
        if (!location) {
            return;
        }

        if (!location.cards) {
            setStage(WorkflowStage.LOCATION_ERROR);
            return dispatch(toastError('', 'ERROR', t('location_no_products')));
        }

        const mandatories = getUnscannedCards(true);
        const optionals = getUnscannedCards(false);

        // We have a location that has cards
        if (mandatories.length > 0) {
            // Still have cards to scan?
            return setStage(WorkflowStage.SCAN_CARD);
        }
        if (optionals.length > 0) {
            return setStage(WorkflowStage.READY_TO_SEND);
        }

        return setStage(WorkflowStage.DONE);
    }

    function handleTableClick(evt: any) {
        let card = getCardById(countedCards, evt.currentTarget.id);
        if (card != null) {
            setCurrentCard(card);
            setShowModal(true);
        }
    }

    function ScanInventoryInstructions() {
        let msg = t('scan_location');
        switch (stage) {
            case WorkflowStage.SCAN_LOCATION:
                msg = t('scan_location_instruction');
                break;
            case WorkflowStage.LOCATION_ERROR:
                msg = t('invalid_location');
                break;
            case WorkflowStage.SCAN_CARD:
                msg = t('scan_card');
                break;
            case WorkflowStage.CARD_SCANNED:
                msg = t('enter_quantity');
                break;
            case WorkflowStage.CARD_ERROR:
                msg = t('invalid_card');
                break;
            case WorkflowStage.READY_TO_SEND:
                msg = t('optional_cards');
                break;
            case WorkflowStage.DONE:
                return null;
            default:
                msg = t('unknown_action');
                break;
        }

        return (
            <span style={{ marginBottom: '10px' }}>
            <Info size={16} className="me-1" style={{ marginTop: '-5px', marginRight: '5px' }} />
            <span style={{ color: '#383434', fontStyle: "italic", fontWeight: "500" }}>{msg}</span>
        </span>
        );
    }

    function getCardById(src: ICard[], id: string): ICard | null {
        if (location === undefined) {
            return null;
        }
        if (location.cards === undefined) {
            return null;
        }
        let card = src.find(card => card.id === id);
        if (card !== undefined) {
            return card;
        }
        return null;
    }

    const handleScannedCode = (data: string) => {
        if (/^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i.test(data)) {
            data = data.split('/').pop() || '';
        }

        switch (stage) {
            case WorkflowStage.SCAN_LOCATION:
                locationProbe2(data)
                    .then(res => {
                        let probedLocation = res as ILocation;
                        if (probedLocation.cards === undefined) {
                            setStage(WorkflowStage.LOCATION_ERROR);
                            setErrors(current => [...current, t('location_no_bins')]);
                        } else {
                            setErrors([]);
                            setStage(WorkflowStage.SCAN_CARD);
                        }
                        return dispatch({ type: actionTypes.PROBE_LOCATION2, payload: probedLocation });
                    })
                    .catch(err => {
                        console.error(err);
                        return dispatch(toastError('', 'ERROR', t('unknown_location')));
                    });
                break;

            case WorkflowStage.SCAN_CARD:
            case WorkflowStage.READY_TO_SEND:
                if (location && location.cards) {
                    let card = countedCards?.find(e => e.rfid === data) || location.cards.find(card => card.rfid === data);

                    if (!card) {
                        return dispatch(toastError('', 'ERROR', t('unknown_card')));
                    }

                    setCurrentCard(card);
                    setShowModal(true);
                }
                break;

            case WorkflowStage.CARD_SCANNED: // Unused?
            case WorkflowStage.CARD_ERROR: // Unused?
                debugger;
                break;

            default:
                break;
        }
    };

    function exclude(arr1: Array<ICard>, arr2: Array<ICard>): Array<ICard> {
        if (arr2 === undefined) {
            return arr1;
        }
        let res: Array<ICard> = [];
        for (let i = 0; i < arr1.length; i++) {
            if (arr2.find(e => e.id === arr1[i].id)) {
                continue;
            }
            res.push(arr1[i]);
        }
        return res;
    }

    function InfoButton(msg: any) {//TODO fix this: msg should be a string
        if (msg.msg === undefined) {
            return null;
        }
        if (stage === WorkflowStage.DONE) {
            return (<p>{msg.msg}</p>)
        }
        return (<p className="border rounded p-1" onClick={() => { setShowCardsToScan(true) }}>{msg.msg}</p>)
    }

    function LocationInformation() {
        if (location === undefined) {
            return null;
        }

        console.log('workflow:', stage);

        if (location.cards !== undefined) {
            let optionals = getUnscannedCards(false);
            let mandatories = getUnscannedCards(true);
            let cardHint;
            let msg;

            if (mandatories.length > 0) {
                cardHint = mandatories[0];
                msg = t('scan_remaining_mandatory', { count: mandatories.length, productCode: cardHint.product_code });
            } else {
                if (optionals.length > 0) {
                    cardHint = optionals[0];
                    msg = t('scan_remaining_optional', { productCode: cardHint.product_code });
                } else {
                    msg = t('all_scanned_ready_to_send');
                }
            }

            return (
                <>
                    <ScanStatusWidget location={location.name} totalCards={location.cards.length} scanned={countedCards ? countedCards.length : 0} />
                    <InfoButton msg={msg} />
                </>
            );
        }

        return null;
    }

    function handleModalSubmit(values: any) {
        if (location === undefined) {
            console.error('handleModalSubmit, currentLocation is undefined');
            return;
        }
        if (location.cards === undefined) {
            console.error('handleModalSubmit, currentLocation.cards is undefined');
            return;
        }
        setCurrentCard(undefined);
        setShowModal(false);
        manageWorkflow();
        return dispatch({ type: actionTypes.UPDATE_LOCATION, payload: values });
    }

    /**
     *
     * @param mandatory true: get the mandatory cards; false: get all cards
     * @returns
     */
    function getUnscannedCards(mandatory: boolean): Array<ICard> {
        if (location !== undefined) {
            if (location.cards !== undefined) {
                // debugger;
                if (countedCards === undefined) {
                    return location.cards;//nothing scanned yet
                }

                if (mandatory === true) {
                    return exclude(location.cards.filter(c => c.scan_mandatory == mandatory), countedCards);
                }
                return exclude(location.cards, countedCards);
            }
        }
        return [];
    }

    function resetOK() {
        setShowResetConfirmation(false);
        setStage(WorkflowStage.SCAN_LOCATION);
        return dispatch({
            type: actionTypes.CLEAR_LOCATION
        })
    }

    function ResetButton() {
        if (location === undefined) {
            return null;
        }

        return (
            <>
                <Button style={{ display: 'flex', justifyContent: 'center', marginTop:'5px' }} variant="outline-danger" onClick={() => {
                    if (location === undefined) {
                        return;
                    }
                    setShowResetConfirmation(true);
                }}>{t('cancel')}</Button>
            </>
        )
    }

    return <>
        <LocationInformation />
        <ScanInventoryInstructions />
        <ResetConfirmationModal
            show={showResetConfirmation}
            headMsg={t('cancel') + "?"}
            msg={t('are_you_sure_cancel_inventory')}
            closeHandler={() => setShowResetConfirmation(false)}
            submitHandler={resetOK}
        />

        {errors.length !== 0 && (
            <div className="alert alert-danger">
                <h5 className="mb-2">{t('error')}</h5>
                <ul className="list-unstyled mb-0">
                    {errors.map((err, index) => (
                        <li key={index}>➤ {err}</li>
                    ))}
                </ul>
            </div>
        )}

        {stage !== WorkflowStage.DONE ?
            <QRScanner onResult={handleScannedCode} /> :
            null
        }
        <ResetButton />
        {currentCard === undefined ? null :
            <InventoryCountModal
                card={currentCard}
                show={showModal}
                submitHandler={handleModalSubmit}
                cancelHandler={() => {
                    setCurrentCard(undefined);
                    setShowModal(false);
                }}
            />
        }
        <CardsToScanModal
            show={showCardsToScan}
            cardList={getUnscannedCards(false)}
            closeHandler={() => setShowCardsToScan(false)}
        />

        {stage === WorkflowStage.READY_TO_SEND || stage === WorkflowStage.DONE ?
            <Button
                style={{ marginTop: '1rem' }}
                disabled={sendButtonDisabled}
                onClick={() => {
                    if (location !== undefined) {
                        setSendButtonDisabled(true);
                        submitInventoryCounts2(location, countedCards)
                            .then(res => {
                                setCurrentCard(undefined);
                                setStage(WorkflowStage.SCAN_LOCATION);
                                setSendButtonDisabled(false);
                                dispatch({ type: actionTypes.CLEAR_LOCATION });
                                return dispatch(toastSuccess(t('success'), t('order_success')));
                            })
                            .catch(err => {
                                setSendButtonDisabled(false);
                                return dispatch(toastError(err, 'ERROR'));
                            });
                    }
                }}
            >
                {t('send')}
            </Button>
            : null}
        <CountedCardsTable cards={countedCards} clickHandler={handleTableClick} />
    </>
}

const mapStateToProps = (state: any) => ({
    location: state.inventoryLocation.location,
    countedCards: state.inventoryLocation.countedCards,
});

export default connect(mapStateToProps)(ScanInventory);
