import React from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import '../assets/styles/App.css'
import {useTranslation} from "react-i18next";

const TenantNotFound :  React.FC = () => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    return (
        <Container className="mt-5 Login">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h2 className="text-center">{t('non_tenant_welcome')}</h2>
                </Col>
            </Row>
        </Container>
    );
}

export default TenantNotFound;
