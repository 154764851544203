import { stat } from "fs";
import { IFooState } from "../models/types";

export const initialFooState: IFooState = {
    bar: 0
};
export const foo = (state = initialFooState, action: any) => {
    // console.log(`fooReducer.foo: state = ${JSON.stringify(state)}, action = ${JSON.stringify(action)}`)
    // return state;
    switch (action.type) {
        case 'a': {
            console.log('fooReducer case A')
            return {
                bar: 42
            }
        }
        case 'b':
            {
                console.log('fooReducer case B')
                return {
                    bar: action.payload.bar
                }
            }
        default: {
            // console.log('fooReducer case default')
            return state;
        }

    }
};


