import ApiClient from "../utilities/http";
import { Endpoints } from "../utilities/endpoints";
import * as actionTypes from "../actionsTypes";

import { isSubmitting, toastError } from "./formInputAction"
import { endSubmitting } from "./loadActions";
import { get } from "lodash";
import { ILocation } from "../models/ILocation";

export const locationProbe = (code: string) => {
    return (dispatch: any) => {
        dispatch(isSubmitting({
            submitButton: true,
        }));
        let url = Endpoints.LOCATION + '?location=' + encodeURI(code);
        console.log('locationProbe, calling', url)
        ApiClient.get(url)
            .then(function (response) {
                dispatch(endSubmitting({
                    submitButton: false,
                }));
                if ((get(response, 'statusText').toLowerCase() === 'ok') || (get(response, 'status') === 200)) {
                    if (response.data.errors !== undefined) {
                        if (response.data.errors.length !== 0) {
                            let errMsg = response.data.errors.join(', ');
                            dispatch(toastError({}, 'Unknown Scanned Location', 'The scan code does not resolve to any location'))
                            return;
                        }
                    }
                    const locationItem: ILocation = get(response, 'data.location');
                    // console.log('30. locationItem', locationItem);

                    if (locationItem) {
                        console.log('36.locationProbe, dispatching')
                        dispatch({
                            type: actionTypes.PROBE_LOCATION,
                            payload: { ...locationItem }
                        })
                        return;
                    }
                }
                dispatch(toastError({}, 'Unknown Scanned Location', 'The scan code does not resolve to any location'))
            })
            .catch(function (error) {
                dispatch(toastError(error, 'Error Fetching Location', 'Location could be fetched, please try again'))
            })
    }
}


export async function locationProbe2(code: string) {
    let url = Endpoints.LOCATION + '?location=' + encodeURI(code);
    return new Promise(async (resolve, reject) => {

        ApiClient.get(url)
            .then((response) => {
                if ((get(response, 'statusText').toLowerCase() === 'OK') || (get(response, 'status') === 200)) {
                    if (response.data.errors !== undefined) {
                        if (response.data.errors.length !== 0) {
                            let errMsg = response.data.errors.join(', ');
                            console.log(errMsg)
                            toastError({}, 'Unknown Scanned Location', 'The scan code does not resolve to any location')
                            reject(errMsg);
                        }
                    }
                    const location: ILocation = get(response, 'data.location');
                    if (location) {
                        resolve(location);
                    }
                }
                toastError({}, 'Error Fetching Location')
            })
            .catch((error) => {
                toastError(error, 'Error Fetching Location')
                reject(error);
            })
    })
}



