import {connect, useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {assetProbe} from "../../actions/assetActions";
import QRScanner from "../QRScanner";
import {useField} from "formik";
import SearchableSelect from "./SearchableSelectBox";
import {Form} from "react-bootstrap";
import '../../assets/styles/bottom-sheet.css'
import UIButton from "./UIButton";
import {Camera, X, Barcode} from "lucide-react";
import {locationProbe} from "../../actions/locationActions";
import {ILocation} from "../../models/ILocation";
import {SingleValue} from "react-select";
import {Option} from "../../models/types";
import {IAsset} from "../../models/IAsset";
import {useTranslation} from "react-i18next";

interface Props {
    dispatch: Function;
    label: string;
    name: string;
    target?: string;
    scanOnload?: boolean;
    id?: string;
    entity?: any;
    probedLocation: ILocation;
    probedAsset: IAsset;
}

const ScannerField :  React.FC<Props> = ({ ...props}) => {
    const scannerRef = useRef<any>(null);
    const [ pValue, setPValue ] = useState({label: '', value: ''});
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;
    const [scan, setScan] = useState(false);

    const onResultLocation = (data: string) => {
        const locationValue = data.split('/').pop();

        if(locationValue)
        {
            props.dispatch(locationProbe(locationValue))
        }
    }
    const onResultAsset = (data: string) => {
        const urlObj = new URL(data);

        // Use URLSearchParams to get the 'asset' parameter value
        const assetValue = urlObj.searchParams.get("asset");

        if (assetValue) {
            props.dispatch(assetProbe(assetValue));
        }
    };

    const onResult = (props.target || 'location') === 'asset' ? onResultAsset : onResultLocation;
    const probedEntity = (props.target || 'location') === 'asset' ? props.probedAsset : props.probedLocation;

    useEffect(() => {
        if (probedEntity) {
            setPValue({
                value: probedEntity.id,
                label: probedEntity.name,
            });

            setValue({
                value: probedEntity.id,
                label: probedEntity.name,
            });
        }
    }, [props.probedLocation, props.probedAsset]);

    const handleSetValue = (value: SingleValue<Option>) => {
        setValue(value);
    }

    return(
        <>
            <Form.Group controlId={props.id || props.name}>
                <Form.Label>{t(props.label)}</Form.Label>
                <div className='d-flex s-wrapper'>
                    <SearchableSelect wrap={false} value={pValue} onSelect={handleSetValue} {...props}/>
                    <QRScanner compact={true} onResult={onResult} scanOnload={props.scanOnload !== false} id='reader'/>
                </div>
                {/* Error message for Formik */}
                {meta.touched && meta.error ? (
                    <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>
                ) : null}
            </Form.Group>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    probedLocation: state.location.probed_location,
    probedAsset: state.asset.asset
});

export default connect(mapStateToProps)(ScannerField);
