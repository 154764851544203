import React, { MouseEventHandler } from "react";
import { useFormik } from "formik";
import { ICard } from "../models/ICard";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function InventoryCountModal(
    { show, card, submitHandler, cancelHandler }:
        { show: boolean, card: ICard, submitHandler: any, cancelHandler: MouseEventHandler<HTMLButtonElement> }) {

    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);

    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (inputRef.current) {
            //for setting focus on the edit-field
            inputRef.current.focus(); //TODO: does not always work
        }
    }, [show, card]);

    const formik = useFormik({
        initialValues: {
            card: card,
            quantity: (card?.countedInventory !== undefined ? card?.countedInventory : 0),
        },
        onSubmit: submitHandler,
    });

    if (card === undefined) {
        return null;
    }

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{t("number of items in bin")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>{t("product code")} {card.product_code}</p>
                    <p>{card.gtin_name}</p>
                    <form onSubmit={formik.handleSubmit}>
                        <Form.Group className="w-50 mx-auto">
                            <Form.Label>{t("count")}:</Form.Label>
                            <Form.Control
                                type="number"
                                name="quantity"
                                autoFocus={true}
                                ref={inputRef}
                                value={formik.values.quantity}
                                onChange={(e) => {
                                    if (e.currentTarget.validity.badInput) {
                                        formik.setFieldValue('quantity', formik.values.quantity);
                                        return;
                                    }
                                    let newValue = Number(e.currentTarget.value);
                                    if (newValue < 0) {
                                        newValue = 0;
                                    }
                                    formik.setFieldValue('quantity', newValue.toString());
                                }}
                            />
                        </Form.Group>
                        <Row className="justify-content-between">
                            <Col xs="auto">
                                <Button variant="secondary" onClick={cancelHandler}>
                                    {t("cancel")}
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button variant="primary" type="submit">
                                    {t("ok")}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </>
            </Modal.Body>
        </Modal>
    );
}

export default InventoryCountModal;
