import React, {useEffect, useState} from 'react';
import '../assets/styles/App.css';
import {Navbar, Nav, Container, Button, Modal, Dropdown} from 'react-bootstrap';
import { Outlet, useNavigate } from "react-router-dom";
import {getBackgroundURL, getLogoURL, logout} from "../actions/authActions";
import {connect} from "react-redux";
import {IUser} from "../models/IUser";
import ReduxToastr from "react-redux-toastr";
import {Bell, Home, Menu, User} from "lucide-react";
import {isString} from "lodash";
import cookie from "../utilities/cookie";
import defaultBackground from "../assets/images/bg-default.png";
import {Utility} from "../utilities/utility";
import Flag  from 'react-world-flags';

import {useTranslation} from "react-i18next";

interface Props {
    dispatch: Function;
    user: IUser;
    logoUrl?: string,
    backgroundURL?: string,
}
interface LogoProps {
    logoUrl?: string;
}

const Protected :  React.FC<Props> = ({ dispatch, logoUrl , backgroundURL }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState(defaultBackground);
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState<string>(i18n.language); // default language: English

    const handleLogout = () => dispatch(logout());
    const navigate = useNavigate();
    const menuItems = [
        { name: 'Home', icon: Home, target: '/dashboard'},
        // { name: 'Settings', icon: Settings, target: '/setting'},
        { name: 'Logout', icon: User, target: handleLogout },
    ];
    const handleMenuClick = (target: string|Function) => {
        if(isString(target)){
            navigate(target);
            return;
        }
        target();
    }

    // @ts-ignore
    const trans = (txt: string) => i18n.t(txt)

    // Function to switch languages
    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const handleQrCodeClick = () => {
        console.log("handleQRCodeClick");
    };


    if(backgroundURL)
    {
        cookie.put('background_url', backgroundURL)
    }

    if(logoUrl)
    {
        cookie.put('logo_url', logoUrl)
    }

    useEffect(() => {
        dispatch(getLogoURL())
        dispatch(getBackgroundURL())
        const cachedBackgroundURL = cookie.get('background_url');

        if (cachedBackgroundURL) {
            Utility.checkIfImageExists(cachedBackgroundURL).then((exists) => {
                if (exists) {
                    setBackgroundImage(cachedBackgroundURL);
                }
            });
        }
    }, [])

    const handleLanguageChange = (lang: string) => {
        setLanguage(lang);
        i18n.changeLanguage(lang); // Change language in i18n
    };

    const Logo: React.FC<LogoProps> = ({ logoUrl }) => {
        const src = logoUrl || cookie.get('logo_url')
        return (<img src={src} alt="Platform Logo" style={{maxWidth: '150px', maxHeight: '30px'}}/>)
    };

    const MemoizedLogo = React.memo(Logo);

    return (
        <div className="dashboard-app">
            <Navbar bg="primary" variant="dark" className="shadow-md p-1">
                <Container fluid className="d-flex justify-content-between align-items-center">
                    <Navbar.Brand className="fw-bold" onClick={() => navigate('/dashboard')}>
                        <MemoizedLogo logoUrl={logoUrl} />
                    </Navbar.Brand>
                    <div className="d-flex align-items-center">
                        {/* Language Button */}
                        <Dropdown align="end" className="me-2">
                            <Dropdown.Toggle variant="link" className="p-1 text-white" id="language-dropdown">
                                <Flag code={language === 'en' ? 'GB' : language.toUpperCase()} alt="language" className="me-2" style={{ width: '20px' }} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleLanguageChange('nl')}>
                                    <Flag code="NL" alt="Dutch" style={{ width: '20px' }} /> {trans('dutch')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleLanguageChange('en')}>
                                    <Flag code="GB" alt="English" style={{ width: '20px' }} /> {trans('english')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleLanguageChange('de')}>
                                    <Flag code="DE" alt="German" style={{ width: '20px' }} /> {trans('german')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        {/* Bell Icon */}
                        <Button variant="link" className="p-1 text-white" onClick={handleQrCodeClick}>
                            <Bell size={24} />
                        </Button>

                        {/* Menu Icon */}
                        <Button variant="link" className="p-1 text-white" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <Menu size={24} />
                        </Button>
                    </div>
                </Container>
            </Navbar>

            <Modal show={isMenuOpen} onHide={() => setIsMenuOpen(false)} animation={false} className="menu-modal">
                <Modal.Header closeButton className="border-0"></Modal.Header>
                <Modal.Body className="p-0">
                    <Nav className="flex-column">
                        {menuItems.map((item) => (
                            <Nav.Link key={item.name} onClick={() => handleMenuClick(item.target)} className="d-flex align-items-center p-2">
                                <item.icon className="me-3 text-primary" size={20} />
                                {item.name}
                            </Nav.Link>
                        ))}
                    </Nav>
                </Modal.Body>
            </Modal>

            {/* Body Content */}
            <main className="d-flex flex-column min-vh-100 bg-light position-relative overflow-hidden p-2">
                <Outlet></Outlet>
            </main>

            <ReduxToastr
                timeOut={7000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick/>
            {/* Footer */}
            <footer className="bg-light py-3 mt-auto shadow-inner text-center">
                <small>&copy; 2025 Powered by AllTrace</small>
            </footer>
        </div>

    );
}

const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.currentUser,
    logoUrl: state.setting.logoURL,
    backgroundURL: state.setting.backgroundURL
});

export default connect(mapStateToProps)(Protected);
