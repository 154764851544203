import * as actionTypes from '../actionsTypes';
import { ICard } from '../models/ICard';
import { ILocationState } from "../models/ILocation";

const initialLocationState: ILocationState = {
    probed_location: undefined,
    location: undefined,
    currentCard: undefined,
    countedCards: undefined
};

export const location = (state: ILocationState = initialLocationState, action: any): ILocationState => {
    // debugger;//who calls me?

    switch (action.type) {
        case actionTypes.PROBE_LOCATION:
            return {
                ...state,
                probed_location: action.payload
            };
        //FIXME DOES THIS WORK??
        case actionTypes.RESET_LOCATION:
            console.log('reducing reset_location')
            return {
                ...state, location: undefined
            }

        case actionTypes.CURRENT_CARD:
            return {
                ...state,
                currentCard: action.payload
            };

        case actionTypes.UPDATE_CARD://TODO move this to !scanInventoryReducer!
            debugger;
            let newState = Object.assign({}, state);//reset this completely, see what happens
            let card = Object.assign({}, state.currentCard);
            if (card !== undefined) {
                let cid: string = card.id;
                card.countedInventory = action.payload;
                let countedCards = state.countedCards;
                if (countedCards === undefined) {
                    // countedCards = { cid: card };
                } else {
                    // countedCards[cid] = card;
                }
                newState.countedCards = countedCards;
                newState.currentCard = undefined;
            }

            return {
                ...newState,
                // countedCards
                // foo: 'bar'
            }
        // currentCard:undefined,
        // countedCards:action.payload
        // };
        //     }
        //     return state;
        // return {
        //     ...state,
        //     countedCards: action.payload
        // };

        default: {
            return state;
        }
    }
};
