import {IAuthState, ISettingState} from '../models/types';
import * as actionTypes from '../actionsTypes';


export const initialSettingState: ISettingState = {
    logoURL     : undefined,
    backgroundURL     : undefined,
}

export const setting = (state = initialSettingState, action: any) => {
    switch (action.type) {
        case actionTypes.RECEIVE_LOGO_URL:
            return {
                logoURL     : action.payload.logoURL,
            };
        case actionTypes.RECEIVE_BACKGROUND_URL:
            return {
                backgroundURL     : action.payload.backgroundURL,
            };
        default: {
            return state;
        }
    }
  };

