import React, { useEffect } from "react";
import "../assets/styles/App.css";
import { Button, Col, Container, Row, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Calculator, ClipboardList, ScanEye, UserCircle } from "lucide-react";
import { connect } from "react-redux";
import { getMyActivities } from "../actions/activityActions";
import cookie from "../utilities/cookie";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";

interface Props {
    dispatch: Function;
    activityCount?: number;
}

const Dashboard: React.FC<Props> = ({ dispatch, activityCount }) => {
    const { i18n } = useTranslation();
    // @ts-ignore
    const t = (txt: string) => i18n.t(txt);
    const navigate = useNavigate();

    const openSheet = () => navigate("/inquiry");
    const scanInventory = () => navigate("/scan_inventory");
    const handleActivity = () => navigate("/activity");

    const permissible = cookie.get("permissible")?.split(",") || [];
    const actionButtons = [
        { name: t("inquiry"), icon: ScanEye, clickHandler: openSheet, enabled: true, visible: permissible.includes("Inquiry") },
        { name: t("inventory"), icon: Calculator, clickHandler: scanInventory, enabled: true, visible: permissible.includes("Inventory") },
        { name: t("activity.activity"), icon: ClipboardList, clickHandler: handleActivity, enabled: true, notification_count: activityCount, visible: permissible.includes("Activity") },
    ];
    const user = AuthService.getUser();

    useEffect(() => {
        dispatch(getMyActivities(true));
    }, [dispatch]);

    return (
        <Container className="flex-grow-1 py-2">
            <div className="bg-white rounded shadow-md p-1 mb-2 font-bold">
                <UserCircle style={{ marginTop: "-5px", color: "#014cbe" }} />
                <span className="username-text">{user?.name || t("welcome")}!</span>
            </div>
            <div className="bg-white rounded shadow-md p-4 mb-4">
                <h3 className="h5 mb-3">{t("menu_options")}</h3>
                <Row className="g-3">
                    {actionButtons.filter((button) => button.visible).map((button) => (
                        <Col xs={6} key={button.name}>
                            <div className="position-relative">
                                <Button
                                    variant="light"
                                    disabled={!button.enabled}
                                    onClick={button.clickHandler}
                                    className="d-flex flex-column align-items-center justify-content-center py-3 shadow-sm w-100"
                                >
                                    <button.icon className="mb-2 text-primary" size={24} />
                                    <span className="small">{button.name}</span>
                                </Button>
                                {!!button.notification_count && button.notification_count > 0 && (
                                    <Badge bg="info" pill className="position-absolute top-0 start-100 translate-middle">
                                        {button.notification_count}
                                    </Badge>
                                )}
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
};

const mapStateToProps = (state: any) => ({
    activityCount: state.activity.my_activity_count,
});

export default connect(mapStateToProps)(Dashboard);
